import { atom, useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { IIMTokenResponse } from '@/httpRequest/modules/imInfo/imToken'
import { TUserInfo } from '@/httpRequest/modules/user/userInfo'

type Unsubscribe = () => void
interface SyncStorage<Value> {
  getItem: (key: string, initialValue: Value) => Value
  setItem: (key: string, newValue: Value) => void
  removeItem: (key: string) => void
  subscribe?: (
    key: string,
    callback: (value: Value) => void,
    initialValue: Value
  ) => Unsubscribe
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commonStorageOption: SyncStorage<any> = {
  getItem(key) {
    const storedValue = localStorage.getItem(key)
    return JSON.parse(storedValue ?? 'false')
  },
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  removeItem(key) {
    localStorage.removeItem(key)
  }
}

const initUserInfo = {
  avatar_url: '',
  balance: 0,
  birthday: '',
  description: null,
  email: '',
  followers: 0,
  following: 0,
  gender: 0,
  id: '',
  income: 0,
  mobile: null,
  nickname: null,
  quantity: 0,
  referral_code: '',
  region: null,
  author_id: '',
  register_type: '',
  created_at: '',
  bonus: 0,
  score: 0
}

export const userAtom = atomWithStorage<TUserInfo>(
  'userInfo',
  initUserInfo,
  commonStorageOption,
  { getOnInit: true }
)
userAtom.debugLabel = 'userAtom'

export const loginStateAtom = atomWithStorage<boolean>(
  'loginState',
  false,
  commonStorageOption,
  { getOnInit: true }
)
loginStateAtom.debugLabel = 'loginStateAtom'

export const useUserAtom = () => {
  const [user, setUser] = useAtom(userAtom)

  const updateUser = (newUserData: Partial<TUserInfo>) => {
    setUser((prevUser: TUserInfo) => ({ ...prevUser, ...newUserData }))
  }

  return {
    user,
    updateUser
  }
}

const initUserIMInfo = {
  avatar_url: null,
  im_token: '',
  name: '',
  user_id: ''
}

const userIMInfoAtom = atom<IIMTokenResponse>(initUserIMInfo)
userIMInfoAtom.debugLabel = 'userIMInfoAtom'

export const useUserIMInfoAtom = () => {
  const [userIMInfo, setUserIMInfo] = useAtom(userIMInfoAtom)

  return {
    userIMInfo,
    setUserIMInfo
  }
}
