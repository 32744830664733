import sensors from 'sa-sdk-javascript'

import { getGlobalConfig } from './utils'

const ENVIRONMENT = getGlobalConfig().VITE_ENVIRONMENT

const SENSORS_TRACK_URL = getGlobalConfig().VITE_SENSORS_TRACK_URL

const productionMode = ['prod', 'dev'].includes(ENVIRONMENT)

sensors.init({
  server_url: SENSORS_TRACK_URL,
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'beacon',
  show_log: !productionMode,
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'not_collect'
  }
})

// 注册公共属性
sensors.registerPage({
  platform: 'web',
  referrer: document.referrer
})

sensors.use('PageLeave', {})

sensors.quick('autoTrack') //用于采集 $pageview 事件。
